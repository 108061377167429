<script>
import Banner from "@/components/Banner.vue";
import cardPartners from "@/components/cardPartners.vue";

export default {
  name: 'Partners',
  components: {
    Banner,
    cardPartners,
  },
  data(){
    return {
      imageBanner:'banner_partners.jpg',
      partnerCards:[
        {
        title:"MARBRERIE",
        content:[
          {
            name:"Marbrerie Le Berre",
            text:"L’équipe des marbriers Le Berre sont des professionnels du secteur qui partagent nos convictions et nos valeurs. La qualité de leur travail, leur disponibilité, et leur efficacité contribuent à leur notoriété.",
            image:"partenaire_marbrerie.png"
          }
        ]
        },
        {
        title:"THANATOPRAXIE",
        content:[
          {
            name:"Morbihan thanatopraxie",
            text:"Morbihan Thanatopraxie est spécialisé dans tous les soins de conservation des défunts. Nous travaillons en étroite collaboration pour réaliser des prestations de qualités qui correspondent à vos attentes.",
            image:"partenaire_thanato.jpg"
          }
        ]
        },
        {
        title:"FLEURISTE",
        content:[
          {
            name:"Maly’s fleurs",
            text:"Élodie Raude est fleuriste et vient d’installer une nouvelle enseigne à Crac’h, nous apprécions la qualité de son travail et il est important pour nous d’avoir des partenaires à proximité.\n La finesse et la beauté de ses créations sauront répondre à vos attentes et embellir l’hommage consacré à vos proches.",
            image:"partenaire_fleuriste.png"
          }
        ]
        },
        {
        title:"CERCUEILS ET URNES",
        content:[
          {
            name:"Canard",
            text:"L’entreprise familiale Canard-sefic est basée dans l’Allier et propose des gammes de qualité et respectueuses de l’environnement.\n Les cercueils sont intégralement fabriqués et façonnés par leurs équipes. ",
            image:"partenaire_cerceuils.jpg"
          }
        ]
        },
        {
        title:"HÔTELS",
        content:[
          {
            name:"Breizh hôtel",
            text:"Breizh hôtel est situé au cœur de la commune de Crac’h, il est spécialisé dans l’accueil de groupes. Les chambres sont douillettes et la décoration est sobre sur le thème du bord de mer.\nd L’hôtel vous propose également des petits-déjeuners et possède un restaurant avec une cuisine simple et familiale.",
            image:"partenaire_hotel1.jpg"
          },
          {
            name:"Le marin",
            text:"L’hôtel le Marin est situé sur le port de Saint Goustan à Auray. C’est un hôtel de charme, confortable et familial. Les petits-déjeuners sont proposés si souhaités.",
            image:"partenaire_hotel2.png"
          },
          {
            name:"Best Western",
            text:"Un hôtel très agréable avec un restaurant de qualité, des prestations en option sont disponibles comme les petits déjeuners. L’hôtel est situé dans Auray à proximité du port de saint Goustan.",
            image:"partenaire_hotel3.png"
          },
        ]
        },
        {
        title:"RESTAURANTS",
        content:[
          {
            name:"Huitres Henry",
            text:"La dégustation des huîtres Henry est située sur la commune de La Trinité sur Mer à quelques pas de Crac’h. Ouverte toute l’année, elle propose aussi des buffets pour des évènements.",
            image:"partenaire_restau1.png"
          },
          {
            name:"La crêperie du bourg",
            text:"Ce restaurant propose diverses formules de brasserie et de crêperie, une salle peut être privatisée pour plus d’intimité et des buffets et goûters peuvent être proposés.",
            image:"partenaire_restau2.jpg"
          },
          {
            name:"Best Western",
            text:"Située près du jardin du souvenir, l’enseigne propose des buffets pour toutes les occasions et à toute heure de la journée. La cuisine est dite “du terroir” et de qualité.\n Le chaudron est situé à Auray sur la route du Bono.",
            image:"partenaire_restau3.jpg"
          },
        ]
        },
      ]
    }
  }
}

</script>

<template>
  <div class="mainContainer">
    <Banner textBanner="PARTENAIRES" :imageBanner="imageBanner"/>
    <div class="container sectionContainer">
      <div class="partnerSection" v-for="card in partnerCards" :key="card.title">
        <cardPartners :title="card.title" :content="card.content"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sectionContainer{
  display:flex;
  flex-direction:column;
  align-items: center;
}

.partnerSection{
  background:var(--paleBlue);
  margin-top:70px;
  padding:2rem;

}

@media screen and (max-width: 760px) {
  .container > div {
    width:100%;
    min-width:0;
  }
}

</style>
