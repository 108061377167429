<script>
import Button from "@/components/Basics/Button.vue"
export default {
  name: 'cardArticle',
  components: {
    Button,
  },
  props:{
    id:String,
    thumbnail: String,
    title:String,
    editable:Boolean,
    content:Object,
  },
  methods:{

  },
  data(){
    return {
      editableCheck:this.editable,
    }
  },
}
</script>

<template>
<div class="cardArticle">
   <img :src="`${require('@/assets/Images/blog/thumbnail/' + thumbnail)}` ">
   <p> {{title}} </p>
   <Button  v-if="!editableCheck" class="button" size="small" color="orange" link="Article" :paramsLink="{imageBanner:this.thumbnail,title:this.title,id:this.id,content:this.content}" >Lire plus</Button>
   <Button  v-if="editableCheck" class="button" size="small" color="orange" link="EditContent" :paramsLink="{imageBanner:this.thumbnail,title:this.title,id:this.id,content:this.content}" >Modifier</Button>
</div>
</template>

<style scoped>
  .cardArticle{
    width:300px;
    display:flex;
    flex-direction:column;
    margin:2rem;
    box-shadow: 0px 5px 10px  rgba(0, 0, 0, 0.20);
    border-radius:10px;
    background:white;

  }
  .cardArticle img{
    width:100%;
    border-radius:10px;
  }
  .cardArticle p{
    font-weight:bold;
    margin:1.5rem 1rem;
    height:50px;
  }

  .cardArticle .button{
    width:100px;
    margin:1rem;
    align-self:flex-end;

  }




</style>
