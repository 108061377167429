<script>
import Button from "@/components/Basics/Button.vue"

export default {
  name: 'homeCardImage',
  components: {
    Button,
  },
  props:{
    title: String,
    text: Array,
    image:String,
    link:String
  },
  computed:{

  },
  methods:{

 }

}

</script>

<template>
<div class=" container">
  <h2 class="mobile">{{title}}</h2>
  <div class="homeCardImage">
    <div class="left">
      <img :src="`${require('@/assets/Images/' + image)}` ">
    </div>
    <div class="right">
        <h2 class="desk">{{title}}</h2>
      <p v-for="(val, index) in text" :key="index" v-html="val"></p>
      <Button v-if="link" size="large" color="orange" :link="link">EN SAVOIR PLUS</Button>
    </div>
  </div>
</div>
</template>

<style scoped>

.container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

}
.mobile{
  display:none;
}


.homeCardImage{
  display:flex;
  justify-content: center;
  align-items: center;
  padding:1rem 0;
}
.homeCardImage {
  display:flex;
}
.homeCardImage img{
  border-radius: 50%;
  width:100%;
  max-width:600px;
}
.homeCardImage p{
  margin-bottom:1rem;
}
.homeCardImage h2{
  margin-bottom:2rem;
}


.homeCardImage .right{
text-align: center;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 50%;
padding:1rem;
}
.homeCardImage .left{
width: 50%;
padding:2rem;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

@media screen and (max-width: 960px) {

  .desk{
    display:none;
  }
  .mobile{
    display:block;
  }
  .homeCardImage{
    display:flex;
    flex-direction:column;
  }
  .homeCardImage img{
    max-width:400px;
  }
  .homeCardImage .right{
    width:90%;
  }
  .homeCardImage .left{
    width:90%;
  }
}

</style>
