import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue';
import Partners from '@/views/Partners.vue';
import Blog from '@/views/Blog.vue';
import ListEditContent from '@/views/CMS/ListEditContent.vue';
import EditContent from '@/views/CMS/EditContent.vue';
//import Contact from '@/views/Contact.vue';
import About from '@/views/About.vue';
import Service from '@/views/Service.vue';
import Article from '@/views/Article.vue';
import PageNotFound from '@/views/PageNotFound.vue';

Vue.use(VueRouter)

const routes = [
  {
    name:'Home',
    path:'/',
    component:Home
  },
  {
    name:'Partners',
    path:'/Partenaires',
    component:Partners
  },
  {
    name:'Blog',
    path:'/Blog',
    component:Blog,
  },
  {
    name:'Contact',
    path:'/Contact',
    component:PageNotFound,
  },
  {
    name:'About',
    path:'/A-propos',
    component:About
  },
  {
    name:'Service',
    path:'/Prestations',
    component:Service,
    props:true,
  },
  {
    name:'Article',
    path:'/Article/:id',
    component:Article,
    props:true,
  },
  {
    name:'CGU',
    path:'/CGU',
    component:PageNotFound,
  },
  {
    name:'Legal',
    path:'/Mentions-legales',
    component:PageNotFound,
  },
  {
    name:'Datas',
    path:'/Protection-des-donnees',
    component:PageNotFound,
  },
  {
    name:'ListEditContent',
    path:'/Liste-contenu-editable',
    component:ListEditContent,
  },
  {
    name:'EditContent',
    path:'/Edition/:id',
    component:EditContent,
    props:true,
  },
  {
    name:'PageNotFound',
    path:'/:patMatch(.*)',
    component:PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
      return { x: 0, y: 0 ,behavior:'smooth'}
  }
})

export default router
