<script>
export default {
  name: 'Banner',
  props:{
    textBanner:{
      type:String,
      default:'false',
    },
    imageBanner:{
      type:String,
      default:'false',
    },
    sizeBanner:{
      type:String,
      default:'small'
    }
  },
  computed:{
    styleBanner:function(){
      if(this.sizeBanner == "large"){
        return ("banner-"+this.sizeBanner);
      }
      return ("banner-small");
    },
  }

}

</script>

<template>
  <div id="banner" :class="styleBanner" :style="[imageBanner != 'false' ? { backgroundImage: `url(${require('@/assets/Images/banners/' + imageBanner)})` } : {backgroundImage:`none`}]">
    <h1 v-if="textBanner != 'false'">{{textBanner}}</h1>
  </div>
</template>

<style scoped>
#banner h1{
  color:white;
}
#banner{
  display:flex;
  justify-content:center;
  align-items:center;
  background:var(--lightBlue);
  background-repeat:no-repeat;
  background-size: cover;
  background-position:center;
}
.banner-large{
height:40vw;
}
.banner-small{
height:20vw;
min-height:250px;
}

</style>
