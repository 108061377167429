<script>
import Banner from "@/components/Banner.vue";
//import tagButton from "@/components/Basics/Button.vue";
import Section from "@/components/articleSectionServices.vue"
export default {
  name: 'Service',
  components: {
    Banner,
  //  tagButton,
    Section,

  },
  props:{
    currentArticleProps:{
      type:String,
      default:"cr"
    },
  },
  methods:{
    changeArticle:function(tag){
      this.currentArticle = tag;
    },
    switchSelect(event) {
      this.currentArticle = event.target.value;
    },
    isPair(index){
      return(index % 2 == 0);
    }
  },
  computed:{
    filterArticle:function(){
      return (this.content.filter(article => article.tag==this.currentArticle));
    },

  },
  data(){
    return {
      currentArticle:this.currentArticleProps,
      imageBanner:'banner_partners.jpg',
      content:[
        {title:"Cérémonies religieuses",
        tag:"cr",
        intro:["Les obsèques constituent le rituel durant lequel on se sépare physiquement et spirituellement de la personne décédée.",
        "Les enterrements religieux sont aussi différents qu’il existe de religions.En France, la loi de loi du 15 novembre 1887 sur la liberté des funérailles permet à toute personne de <strong>définir le caractère religieux ou civil de ses obsèques.</strong>",
        "Les entreprises de pompes funèbres exercent une activité de service public, et doivent pouvoir organiser <strong>tout type d’obsèques.</strong>"],
        content:[{
          title:"Démarches pour des obsèques religieuses",
          text:["La société de pompes funèbres désignée par la famille se charge de l’organisation de la cérémonie religieuse.",
          "En fonction du culte, l’officiant religieux sera contacté afin de définir le jour et l’horaire de la célébration. Il sera ainsi évoqué la mémoire du défunt, le choix de musiques religieuses, et un livret de messe pourra être établi.",
          "Il est de mise d’associer le symbole représentant la religion du défunt sur le cercueil.",
          "L’avis de décès précise généralement le lieu où se tiendra la cérémonie religieuse."],
          image:"/services/articles/prestations_1.jpg"
        },
        {
          title:"Quels sont les rites religieux pour les obsèques ?",
          text:["Différents rites préalables à un enterrement religieux peuvent se mettre en place tels que :",
          "<ul><li>Veillées funèbres avec lecture de prières</li><li>Toilette rituelle</li><li>Mise en bière en présence de l’officier cultuel</li></ul>",
          "La religion musulmane et la religion juive ne permettent pas la pratique de soins de conservation. Les fleurs ne sont pas de mise lors des obsèques de ces cultes, et le type de sépulture est l’inhumation.",
          "L’avis de décès précise généralement le lieu où se tiendra la cérémonie religieuse."],
          image:"/services/articles/prestations_2.jpg"
        },
        {
          title:"Déroulement d’un enterrement",
          text:["Dans la religion catholique et orthodoxe, un passage à l’église est organisé.",
          "Dans la religion musulmane, l’enterrement se déroule au cimetière.",
          "Dans la religion juive, la cérémonie religieuse se déroule également au cimetière.",
          "Les familles conviennent généralement d’un entretien avec le représentant du culte avant la cérémonie.",
          "Durant les obsèques, l’entreprise funéraire reste présente et se charge de l’accueil des proches, du transport du cercueil dans un corbillard, de la réception et de la disposition des fleurs.",
          "La durée des obsèques est variable (entre 40 minutes pour une bénédiction catholique à 1 heure pour une messe).",
          "L’enterrement pourra prévoir l’intervention de proches en fonction du culte.",
          "Durant la cérémonie, des prières et des musiques sont diffusées en fonction de la religion. Des gestes symboliques et des prières sont souvent associés au départ dans la foi d’un défunt :",
          "<ul><li>Bougies et bénédiction du cercueil dans la foi catholique</li><li>Le rite de Kri’a dans la foi juive</li><li>La prière dans le rite musulman</li><ul>"],
          image:"/services/articles/prestations_3.jpg"
        },
      ]},
      {title:"Cérémonies laïques",
      tag:"cl",
      intro:["Les obsèques constituent le rituel durant lequel on se sépare physiquement et spirituellement de la personne décédée.L’enterrement laïque correspond à la volonté de ne pas intégrer de passage dans un culte religieux lors d’obsèques ou de n’y affecter aucune notion religieuse."],
      content:[{
        title:"Qu’est-ce que des obsèques laïques ?",
        text:["L’enterrement laïque correspond à la volonté de ne pas intégrer de passage dans un culte religieux lors d’obsèques ou de n’y affecter aucune notion religieuse (prières, bénédiction…). Souvent indiquée de son vivant par le défunt, ou du choix de la famille, le caractère religieux ou laïque des obsèques est prévu par la loi du 15 novembre 1887 sur la liberté des funérailles.",
        "Les obsèques laïques, appelées également obsèques civiles, ne sont pas réalisées par un officier d'État Civil ou un représentant de la Mairie. Cette possibilité entreprise par une proposition de loi  en 2014 n’a pas été votée. En effet, la réalisation d’obsèques nécessite l’obtention d’un diplôme de maître de cérémonie."],
        image:"/services/articles/prestations_4.jpg"
      },
      {
        title:"Démarches pour un enterrement civil",
        text:["Comme pour des funérailles religieuses, la famille choisit une entreprise de pompes funèbres qui réalise l’ensemble des obsèques.",
        "Les professionnels du funéraire se chargent des formalités pour la prise en charge du défunt et l’organisation du dernier hommage.",
        "La déclaration de décès est faite par l’entreprise, et permet de réaliser le transport du corps, et de se voir délivrer les autorisations nécessaires à l’inhumation ou à la crémation."],
        image:"/services/articles/prestations_5.jpg"
      },
      {
        title:"Où se déroulent des obsèques civiles ?",
        text:["Les obsèques civiles peuvent se dérouler :",
        "<ul><li>Au cimetière. Dans les allées attenantes ou devant la sépulture où reposera le défunt.</li><li>Au crématorium. Les crématoriums disposent généralement de salles de cérémonies permettant de rendre un dernier hommage au défunt.</li><li>Dans une salle de cérémonie privée. Certaines entreprises de pompes funèbres disposent de salles de cérémonies pouvant accueillir la famille et les proches afin de célébrer les obsèques civiles du défunt.</li></ul>",
        "Dans la religion juive, la cérémonie religieuse se déroule également au cimetière.",
        "Les familles conviennent généralement d’un entretien avec le représentant du culte avant la cérémonie.",
        "Durant les obsèques, l’entreprise funéraire reste présente et se charge de l’accueil des proches, du transport du cercueil dans un corbillard, de la réception et de la disposition des fleurs.",
        "La durée des obsèques est variable (entre 40 minutes pour une bénédiction catholique à 1 heure pour une messe).",
        "L’enterrement pourra prévoir l’intervention de proches en fonction du culte.",
        "Durant la cérémonie, des prières et des musiques sont diffusées en fonction de la religion. Des gestes symboliques et des prières sont souvent associés au départ dans la foi d’un défunt :",
        "<ul><li>Bougies et bénédiction du cercueil dans la foi catholique</li><li>Le rite de Kri’a dans la foi juive</li><li>La prière dans le rite musulman</li><ul>"],
        image:"/services/articles/prestations_6.jpg"
      },
      {
        title:"Où se déroulent des obsèques civiles ?",
        text:["On distingue plusieurs étapes qui constituent la célébration des obsèques civiles :",
        "<ul><li>Relater le départ défunt</li><li>Lecture de textes (poèmes, ou écrits) par les parents proches (époux, épouse, enfants…) ou amis</li><li>Faire un retour sur la vie du défunt</li><li>Diffusion de musiques choisies par les proches et qui étaient appréciées du défunt ou lui correspondent</li><li>Moment de recueillement en musique ou non (minute de silence)</li><li>Clôture de la cérémonie en relatant les valeurs du défunt et le futur</li></ul>",
        "Le métier de maître de cérémonie consiste à prendre la parole pour relater la vie du défunt et coordonner les interventions. Les participants, le choix de musiques ou d’un geste d’hommage est organisé en amont avec la famille. Ce type de cérémonie permet une certaine liberté que n'offrent pas toujours les cérémonies religieuses.",
        "L’entreprise dispose généralement de matériel de sonorisation ou de diffusion d’images (diapo, photos) qu’elle met à disposition lors des funérailles si le lieu choisi le permet."],
        image:"/services/articles/prestations_7.jpg"
      },
    ]},
    {
      title:"La crémation",
      tag:"crem",
      intro:["La crémation se pratique exclusivement dans des établissements spécialisés appelés crématoriums et consiste à brûler le corps du défunt."],
      content:[{
        title:"La crémation",
        text:["La crémation est un mode de sépulture de plus en plus choisi en France. Elle se pratique exclusivement dans des établissements spécialisés appelés crématoriums.",
        "La crémation consiste à brûler le corps du défunt dans un four spécialement aménagé à cet effet.",
        "Le coût des obsèques est compris entre 3 500 et 4 500 € en moyenne. Il a longtemps été considéré qu’une crémation revient moins cher, ce qui s’avère souvent faux, notamment lorsque l’urne doit être inhumée. Cela s’explique du fait de l’inhumation de l’urne. En effet, aux opérations courantes s’ajoutent alors les frais d’ouverture de la concession."],
        image:"/services/articles/prestations_8.jpg"
      },
      {
        title:"Historique et culture de la crémation",
        text:["Durant l’âge de bronze on a pu constater que certaines civilisations pratiquaient déjà ce mode d’enterrement.",
        "La plus ancienne crémation remonterait à 22 000 ans av. J-C. Des ossements ont été découverts en Australie, broyés et inhumés avec les restes du foyer.",
        "A partir de 9000 av. J-C, on constate davantage la pratique, qui se répand à la fin de l’âge de bronze en Europe.",
        "En Asie, le fait de brûler les corps est réalisé depuis le IVème siècle av. J-C et perdure de nos jours.",
        "En Europe au moyen âge, la société chrétienne revient vers la tradition d’inhumer les corps au cimetière, dans une tombe.",
        "Ce n’est qu’au XVIème siècle que l’on recommence à promouvoir la crémation, à la faveur de mesures hygiénistes, notamment en période d’épidémies.",
        "Le premier établissement français est le crématorium du Père Lachaise, inauguré en 1889. La loi de 1887, permet aux français majeurs la liberté des funérailles. Ils sont en droit, dès cette époque, de choisir si leur dépouille est inhumée ou crématisée. Ce n’est qu’en 2008 que la loi vient encadrer la destination des cendres.",
        "La franc-maçonnerie ainsi que la religion protestante ont très tôt promulgué la pratique qui s’est généralisée après la seconde guerre mondiale.",
        "Le terme « crémation » est issu du mot « cremer », qui détermine l’action de brûler un corps. L’emploi de l’expression « incinération » (du latin « cinis », signifiant « cendres ») est courante, mais une différenciation est faite de la part de la profession funéraire pour la distinguer de l’incinération des déchets.",
        "<strong>Culture : la crémation dans le monde</strong>",
        "Dans les pays occidentaux et historiquement catholiques, la crémation est acceptée et de plus en plus pratiquée. En Europe, certains pays comme l’Allemagne ou la Suisse ont des taux de crémations supérieurs à 70%.",
        "Dans les pays asiatiques tel que le Japon, la crémation est le mode de sépulture traditionnel.",
        "En Inde, la religion implique de brûler le corps sur le fleuve du Gange, tandis que dans les pays océaniques tels que l’Indonésie, on érige des tours de crémation, très coûteuses pour la famille. Ces célébrations impliquent de grands rassemblements, ainsi le délai entre la mort du défunt et la cérémonie de crémation peuvent s’étendre à plus d’un an."],
        image:"/services/articles/prestations_9.jpg"
      },
      {
        title:"Crémation et religion",
        text:["Toutes les religions ne tolèrent pas la pratique. Les catholiques ont officiellement accepté la crémation en 1963, tout en préconisant l’enterrement. Il n’est pas de coutume de réaliser une cérémonie d’enterrement en présence de l’urne, mais bien en présence du cercueil lors d’une messe ou d’une bénédiction à l’église. Une note d’un cardinal encadre cependant la destination des cendres",
        "L’islam, l’orthodoxie et le judaïsme, ne tolèrent pas la crémation. Il a toutefois existé un crématorium en Israël, mais qui n’a pas pu être mis en fonctionnement car incendié par un groupe d’ultra-orthodoxes.",
        "L’hindouisme quant à lui, impose la crémation, qui s’inscrit dans le rite antyesti, comprenant la cérémonie funèbre, jusqu’au bûcher, allumé par le fils aîné, où le corps est brûlé.",],
        image:"/services/articles/prestations_10.jpg"
      },
      {
        title:"Organisation d’une crémation / Comment se déroule une crémation ?",
        text:["Au décès, la famille se rapproche de l’entreprise de pompes funèbres de son choix pour organiser les obsèques. Celle-ci s’occupe de réaliser les démarches administratives qui permettront de réaliser les opérations nécessaires : transport de corps, cérémonie, crémation…",
        "Le corps est placé dans un cercueil. Les jours qui précèdent les obsèques permettent aux proches de se recueillir. Le jour de la cérémonie, le cercueil est fermé, et transporté vers le lieu d’hommage (église, salle de cérémonie) ou bien directement au crématorium.",
        "L’hindouisme quant à lui, impose la crémation, qui s’inscrit dans le rite antyesti, comprenant la cérémonie funèbre, jusqu’au bûcher, allumé par le fils aîné, où le corps est brûlé.",
        "<strong>Hommage pour une crémation</strong>",
        "Le crématorium met généralement à disposition une salle d’hommage permettant de réaliser une cérémonie d’adieu au défunt. Elle dure entre 20 minutes et jusqu’à 1 heure en fonction de ce que permet le gestionnaire et n’est pas obligatoire.",
        "La famille peut également célébrer la cérémonie à l’église, ou bien dans une salle de cérémonie mise à disposition par la société funéraire ou dans une salle communale. ",
        "<strong>La crémation du cercueil </strong>",
        "A l’issue de la cérémonie, le cercueil est emmené dans la salle du four crématoire. Les établissements disposent généralement d’un système de vidéo ou bien d’une vitre qui permet d’assister à la mise à la flamme.",
        "Cette action se déroule de la façon suivante :",
        "<ul><li>Introduction du cercueil dans le four chauffé à 850° ou plus. C’est cette chaleur, ainsi que la matière qui y est soumise (le cercueil contenant le corps) qui allume les flammes. Cette opération dure entre 1 et 2 heures.</li><li>Les restes mortels du défunt qui se constituent des os, sont réduits, puis récupérés et broyés. Préalablement, les prothèses en métal seront extraites.</li><li>Les cendres broyées seront placées dans une urne et remises à la famille ou aux pompes funèbres.</li></ul>"],
        image:"/services/articles/prestations_11.jpg"
      },
      {
        title:"Quel cercueil pour une crémation ?",
        text:["En France le cercueil est obligatoire quel que soit le mode de sépulture choisi. Il n’est pas possible de faire des obsèques dans un linceul ou un drap.Lorsque le certificat de décès indique « mise en bière en cercueil hermétique », on insère un second cercueil en zinc dans le cercueil en bois. Cette contrainte est décidée par le médecin en cas de maladie contagieuse, et ne permet pas de procéder à la crémation.",
        "Pour une crémation, le cercueil est généralement en bois tendre, le plus souvent du pin. Il existe différentes formes de cercueils, et différents bois, plus ou moins sublimables. Les bois durs peuvent nécessiter plus de temps dans le four pour se consumer, et le crématorium impose alors un supplément tarifaire.",
        "Les cercueils en cartons ont également fait leur apparition en France depuis plusieurs années. Ils ont longtemps été refusés par plusieurs crématorium à cause de la volubilité des cendres. Aujourd’hui, tous les crématoriums doivent les accepter.",],
        image:"/services/articles/prestations_12.jpg"
      },
      {
        title:"Les cendres du défunt et l’urne",
        text:["Les urnes où sont rassemblées les cendres sont de formes et de matières diverses : cartons, métal, laiton, céramique…",
        "C’est davantage l’urne cinéraire, où sont rassemblées les cendres mortuaires, qui restera la trace physique du corps.",
        "Pour choisir une urne cinéraire, il faut prendre en compte :",
        "<ul><li>La destination des cendres (dispersion, scellement ou inhumation)</li><li>Le budget alloué : le prix de l’urne varie entre 45€ et 250€ ou plus</li><li>La personnalité du défunt : il existe des modèles avec des photos, des motifs, des symboles…</li><li>La corpulence du défunt : si la personne était de corpulence forte, la contenance doit être adaptée (3,5 litres ou plus)</li></ul>"],
        image:"/services/articles/prestations_13.jpg"
      },
    ]},
    {
      title:"L'inhumation",
      tag:"inhu",
      intro:["L’inhumation ou enterrement est le mode de sépulture le plus répandu en France. Il consiste à l’ensevelissement du cercueil au cimetière."],
      content:[{
        title:"Qu’est-ce qu’une inhumation ou enterrement ?",
        text:["L’inhumation ou enterrement est le mode de sépulture le plus répandu en France. Il consiste à l’ensevelissement du cercueil au cimetière.",
        "Il existe plusieurs types de sépultures pour l’inhumation :",
        "<ul><li>Inhumation dans une fosse en pleine terre</li><li>Inhumation dans un caveau en béton avec une ou plusieurs places</li><li>Inhumation dans une cavurne. Petit caveau destinée à accueillir les urnes cinéraires</li><li>Inhumation dans un enfeu, monument funéraire surélevé</li><ul>",
        "Les archéologues ont trouvé des traces d’inhumation au Proche-Orient, datant du Paléolithique. Mais ce n’est qu’au moment de la sédentarisation que ces pratiques funéraires se sont réellement généralisées chez l’Homme."],
        image:"/services/articles/prestations_14.jpg"
      },
      {
        title:"Pourquoi choisir l’inhumation ?",
        text:["Le mode de sépulture est un choix propre au défunt. La loi de 1887 relative à la liberté des funérailles permet à toute personne de choisir l’inhumation ou la crémation.",
        "Elle précise que « Tout majeur ou mineur émancipé, en état de tester, peut régler les conditions de ses funérailles, notamment en ce qui concerne le caractère civil ou religieux à leur donner et le mode de sa sépulture »",
        "Il s’agit souvent d’un choix en lien avec la religion, ou bien d’un choix personnel.",
        "Une personne qui précise ses dernières volontés par écrit lui confèrent une valeur testamentaire. Ce choix doit alors être respecté par les proches.",
        "L’enterrement au cimetière permet à la famille de revenir se recueillir sur le lieu de repos du défunt."],
        image:"/services/articles/prestations_15.jpg"
      },
      {
        title:"Conditions pour l’inhumation d’un défunt",
        text:["Afin de procéder à l’enterrement d’une personne, il est nécessaire de disposer d’un emplacement au cimetière.",
        "L’emplacement est soit une concession, soit un terrain commun.",
        "Dans la religion juive, la cérémonie religieuse se déroule également au cimetière.",
        "La concession funéraire est un emplacement au cimetière délivré par la commune.",
        "Il s’agit d’un contrat d’occupation du domaine public. Le terrain est délivré pour un certain nombre d’années, et le concessionnaire peut y faire poser une pierre tombale.",
        "La concession est à destination :",
        "<ul><li>Familiale, c’est-à-dire pour tous les membres de la famille ou même d’amis</li><li>Individuelle pour une seule personne </li><li>Collective, c’est-à-dire seulement pour les personnes désignées</li><ul>",
        "Le tarif d’un emplacement au cimetière dépend des communes.",
        "Un terrain commun est un emplacement individuel délivré gratuitement par la commune pour une durée maximale de 5 ans. Il ne s’agit pas d’une fosse commune qui n’est plus prévue par le droit français.",
        "Toutes les personnes domiciliées sur la commune, décédées sur la commune, ou y étant inscrites sur la liste électorale peuvent en bénéficier. ",
        "Après le délai de gratuité de 5 ans, les restes sont exhumés et déplacés vers l’ossuaire."],
        image:"/services/articles/prestations_16.jpg"
      },
      {
        title:"Organisation de l’enterrement",
        text:["Les pompes funèbres sont chargées de l’ensemble des formalités administratives relatives à un enterrement. Après la cérémonie d’obsèques, le convoi funéraire rejoint le cimetière où se déroule l’inhumation. Une lecture de textes, de prières ou un passage de musiques est possible avec ou sans représentant d’un culte.",
        "La société de pompes funèbres ou le marbrier funéraire a préalablement procédé à l’ouverture de la sépulture : en effectuant un creusement ou une ouverture de caveau.",
        "Lorsque la famille est prête, les porteurs réalisent la descente du cercueil dans la fosse. Chacun peut ensuite déposer des pétales de rose, une fleur, un peu de terre…",
        "La pierre tombale ou la fosse est ensuite refermée."],
        image:"/services/articles/prestations_17.jpg"
      },
    ]},


    {
      title:"Le transport",
      tag:"trans",
      intro:[""],
      content:[
        {
          title:"Transport de corps avant mise en bière",
          text:["Le lieu de décès détermine s’il y a lieu d’effectuer un transport du corps. Cette opération appelée « transport de corps avant mise en bière » est réalisée par les pompes funèbres dans un véhicule équipé d’un caisson réfrigéré (corbillard). Le délai maximal est de 48 heures.",
          "Le transport de corps par un particulier n’est pas possible sur le territoire français.",
          "Le transport du défunt a lieu :",
          "<ul><li>De l’hôpital vers la morgue si celle-ci se situe dans un bâtiment indépendant</li><li>De l’hôpital vers une chambre funéraire, vers le domicile ou la résidence d’un membre de la famille</li><li>Du domicile vers une chambre funéraire</li><li>De la maison de retraite vers une chambre funéraire</li><li>De la voie publique vers un hôpital ou une chambre funéraire</li></ul>",
          "Le corps du défunt est transporté dans une housse mortuaire, qui est obligatoire.Il est effectué à la demande de la famille ou du directeur d’un établissement de soins ou de la police dans certains cas.",
          "Deux porteurs funéraires se chargent généralement du transport du corps du défunt. L’identité est contrôlée au moyen du bracelet d’identité, posé par l’établissement hospitalier ou par l’opérateur funéraire. Les porteurs funéraires déposent ensuite le corps dans une housse mortuaire, puis sur un brancard. Le brancard est inséré dans un véhicule funéraire spécialement aménagé d’un caisson réfrigéré (corbillard). Le cas échéant, les vêtements du défunt et ses effets personnels sont listés et emmenés.",
          "Arrivé au lieu de destination, des soins de conservation, une toilette mortuaire ou rituelle sont effectués sur demande de la famille. Le corps est ensuite présenté habillé en salon funéraire ou au domicile. Le défunt peut être présenté sur une table réfrigérante qui ressemble à un lit, ou bien en cercueil.",],
          image:"/services/articles/prestations_18.jpg"
        },
        {
          title:"Transport du défunt après mise en bière",
          text:["Le transport après mise en bière s’effectue après la fermeture du cercueil. Il précède généralement les obsèques. Le corbillard est nécessaire pour le transport du cercueil, il rejoindra le lieu de cérémonie défini avec les proches. L’inhumation ou la crémation sont la dernière étape constituant les funérailles.",
          "Certaines entreprises funéraires proposent une place assise dans le véhicule funéraire, ou mettent à disposition des véhicules de suivi spécialement affectés aux proches du défunt.",
          "Le convoi funéraire est généralement réalisé en voiture, mais il est de coutume dans certaines villes de réaliser le cortège à pied lorsque, par exemple, le cimetière est proche de l’église.",
          "Le corbillard qui prend en charge le cercueil, transporte le personnel (chauffeur, maître de cérémonie, porteurs), mais aussi les articles funéraires offerts par les proches : plaques, fleurs… Ce type de véhicule fait l’objet de contrôles spécifiques réguliers."],
          image:"/services/articles/prestations_19.jpg"
        },
        {
          title:"Transport de corps réglementation",
          text:["Le transport de corps, avec ou sans cercueil est encadré par différentes règles :",
          "<ul><li>Lorsque le décès a lieu en milieu hospitalier, le directeur de l’établissement délivre l’autorisation de transport de corps</li><li>L’entreprise munit le défunt d’un bracelet mentionnant son identité, sa date de naissance et de décès s’ils sont connus</li><li>Le médecin qui déclare le décès est habilité à refuser un transport sans cercueil en cas de corps trop dégradé, ou lorsque le défunt était porteur d’une maladie contagieuse. Dans ce dernier cas, le certificat de décès indique « mise en bière immédiate » dans un cercueil simple ou dans un cercueil hermétique</li><li>L’entreprise de pompes funèbres qui réalise le transport transmet à la mairie du lieu de départ du corps, ainsi que celle du lieu d’arrivée une déclaration préalable de transport. Le transport est nécessairement effectué après la déclaration de décès en Mairie</li><li>Le transport de corps avant mise en bière est effectué dans un délai maximum de 48 heures</li><li>Si un proche du défunt n’a pas pu être contacté dans un délai de 10 heures, la personne chez qui le décès a eu lieu peut faire réaliser le transfert du défunt en funérarium</li></ul>"],
          image:"/services/articles/prestations_20.jpg"
        },
        {
          title:"Rapatriement de corps",
          text:["<strong>Rapatriement de corps à l’étranger</strong>",
          "Le transport mortuaire par avion est courant pour les personnes originaires de pays étrangers, ou des départements d’outre-mer. Le rapatriement international est effectué le plus souvent par avion, mais peut être effectué par la route, notamment en Europe, mais aussi par voie maritime.",
          "La législation n’est pas la même que pour l’opération en France. C’est la préfecture du lieu de décès qui délivre les autorisations, que l’entreprise de pompes funèbres transmet au pays de destination. Les documents doivent être remis au transporteur qui les conserve jusqu’à l’arrivée.",
          "Il est souvent nécessaire de se rapprocher du consulat du pays concerné afin de connaître les règles spécifiques de transport mortuaire vers le pays concerné. Certains états exigent :",
          "<ul><li>La présence d’un cercueil hermétique</li><li>Un certificat de non-contagion délivré par le médecin</li><li>Un certificat de non-épidémie délivré par l’Agence Régionale de Santé</li><li>La pratique de soins de conservation</li></ul>",
          "<strong>Comment rapatrier un corps en France ?</strong>",
          "Un laisser passer spécial est accordé par les autorités françaises disposant d’accords avec des pays frontaliers pour l’entrée d’un corps en France par voie terrestre.",
          "À l’arrivée du corps en France, la famille dispose de 6 jours (hors dimanche et jours fériés) pour faire procéder à la sépulture.",
          "Le cercueil en zinc hermétique est exigé pour la plupart des arrivées de corps en France depuis l’étranger (sauf accords européens), empêchant ainsi la crémation.",
          "<strong></strong>",
          "Combien coûte un rapatriement de corps ?",
          "Tout dépend de la destination :",
          "<ul><li>Par avion : à partir de 1200€ selon la compagnie aérienne</li><li>Par la route : à partir de 2€ du kilomètre parcouru</li></ul>",
          "À cela peuvent s’ajouter des frais pour les démarches administratives à réaliser par l’entreprise funéraire. Le taux de TVA pour le transport de corps est à 10%.",
          "Il est important de noter que la plupart des assurances de voyage, ou des assurances de cartes de crédit, prévoient une participation aux frais de transport de corps en cas de décès à l’étranger.",
          "De même, une option « garantie assistance » est proposée dans la majorité des contrats obsèques. Elle permet une prise en charge du transport de corps lorsque le décès survient à plusieurs kilomètres du domicile (généralement plus de 50km)."],
          image:"/services/articles/prestations_21.jpg"
        },
        {
          title:"Questions diverses sur le transport de corps",
          text:["<strong>Comment transporter une urne ?</strong>",
          "En France, la législation n’impose pas de faire appel à une entreprise de transport de corps pour déplacer une urne funéraire.",
          "Pour déplacer des cendres en avion, il faut posséder une autorisation de transport du préfet. Des documents complémentaires peuvent être exigés par le pays de destination en cas de voyage à l’international. Vous pouvez vous mettre en relation avec l’ambassade ou le consulat du pays de destination.",
          "L’urne doit être bien scellée, et accompagnée du certificat de crémation. Les compagnies aériennes acceptent qu’elles soient transportées en bagage à main ou bien en soute.",
          "<strong>Comment rapatrier un corps en avion ?</strong>",
          "Les démarches sont réalisées par une entreprise funéraire. Que le transport se fasse vers le territoire français ou outre-mer, ou bien depuis l’étranger, c’est généralement la société qui se charge d’organiser l’opération.",
          "Le conseiller se mettra en relation avec l’ambassade du pays de destination afin d’obtenir les autorisations nécessaires : laisser passer mortuaire, certificat de non-contagion, attestation de soins de conservation (injections de produits permettant la conservation du défunt), etc.",
          "<strong>D’où vient le mot corbillard ?</strong>",
          "Le mot « corbillard » nous vient de la ville de Corbeil (Essonne). Au moyen-âge, Corbeil est un carrefour commercial avec Paris, et des bateaux à fond plat font régulièrement des allers-retours entre les deux villes, on les appelle « corbeillards ». Au milieu du XIVème siècle, la peste bubonique fait de nombreux morts dans la capitale, et, afin d’évacuer les corps vers le sud, ces mêmes bateaux sont utilisés, ce qui donnera le nom de « corbillard ».",
          "<strong>Qui transporte le corps ?</strong>",
          "Ce sont les employés de pompes funèbres qui sont chargés de transporter le corps d’une personne décédée. Seul ce corps de métier est habilité et dispose des véhicules spécialement habilités pour le transport de défunt.",
          "<strong>Pourquoi un cercueil plombé ?</strong>",
          "Les cercueils hermétiques sont en réalité en zinc. Ils permettent une conservation plus hygiénique du corps, et évitent ainsi que la dégradation ne provoque des désagréments (odeurs par exemple)."],
          image:"/services/articles/prestations_22.jpg"
        },
      ]},

      {title:"Dispersion des cendres",
      tag:"dis",

      content:[
        {

          text:["Dans un jardin du souvenir, un cimetière, en pleine nature ou encore en mer, les cendres peuvent être dispersées en fonction des volontés du défunt et de ses proches selon la réglementation en vigueur."],
          image:"/services/articles/prestations_23.jpg"
        },
      ]},
      {title:"Cerceuils et enblèmes",
      tag:"ceremb",

      content:[
        {

          text:["Nous vous proposons une large gamme en fonction de vos souhaits et de votre budget. Personnalisation possible."],
          image:"/services/articles/prestations_24.jpg"
        },
      ]},
      {title:"Choix des urnes",
      tag:"urnes",
      intro:[
      ],
      content:[
        {

          text:["Un grand choix vous est proposé: métal, bois, terre cuite ou carton. Classiques ou modernes, colorées ou neutres."],
          image:"/services/articles/prestations_25.jpg"
        },
      ]},
      {title:"Marbrerie",
      tag:"marb",

      content:[
        {

          text:["Pierres tombales, monuments, gravures. Nous vous proposons la pose réalisée par notre prestataire ainsi que l’entretien."],
          image:"/services/articles/prestations_26.jpg"
        },
      ]},
      {title:"Gravures",
      tag:"grav",

      content:[
        {
          text:["Elles sont réalisées par un professionnel, sur des monuments, des emblèmes ou des plaques souvenirs."],
          image:"/services/articles/prestations_27.jpg"
        },
      ]},
      {title:"Fleurs",
      tag:"fleurs",

      content:[
        {

          text:["Notre prestataire Maly’s fleurs ou celui de votre choix réalisera des compositions fines et délicates en fonction de vos souhaits. Les fleurs sont de grande qualité et viendront symboliser la mémoire de votre défunt."],
          image:"/services/articles/prestations_28.jpg"
        },
      ]},
      {title:"Salon funéraire",
      tag:"salon",

      content:[
        {

          text:["Pour permettre aux proches de se recueillir en toute intimité et de faire un dernier adieu dans des conditions sereines. Le salon est personnalisable, des cadres photos sont à votre disposition. Vous pouvez également apporter des souvenirs, des dessins et tout ce qui est nécessaire."],
          image:"/services/articles/prestations_29.jpg"
        },
      ]},
      {title:"Films hommages",
      tag:"films",

      content:[
        {

          text:["En plus des musiques, nous vous proposons la réalisation d’un film souvenir. Confiez-nous des photos, des histoires, des anecdotes et nous vous proposerons un montage pour une cérémonie plus personnalisée."],
          image:"/services/articles/prestations_30.jpg"
        },
      ]},
      {title:"Présentations photos",
      tag:"pres",

      content:[
        {

          text:["En plus des musiques, nous vous proposons la réalisation d’un film souvenir. Confiez-nous des photos, des histoires, des anecdotes et nous vous proposerons un montage pour une cérémonie plus personnalisée."],
          image:"/services/articles/prestations_31.jpg"
        },
      ]},
      {title:"Réservation de chambres d'hôtel",
      tag:"reservhot",

      content:[
        {

          text:["Parfois les proches résident loin, et pour vous épargner cette charge supplémentaire, nous vous proposons de nous occuper des réservations en fonction de vos besoins et de votre budget. Nous travaillons avec plusieurs hôtels dans la région qui proposent également des petits déjeuners."],
          image:"/services/articles/prestations_32.jpg"
        },
      ]},
      {title:"Réservation de restaurants",
      tag:"reservres",

      content:[
        {

          text:["Si vous le souhaitez, nous avons la possibilité de vous réserver des restaurants pour partager un moment avec vos proches. Déjeuners, dîners, goûters, ou buffets pour vous permettre de vous restaurer dans un lieu de qualité."],
          image:"/services/articles/prestations_33.jpg"
        },
      ]},
      {title:"Avis de decès dans la presse",
      tag:"avis",

      content:[
        {

          text:["Ce n’est pas obligatoire, mais si vous le souhaitez nous pouvons faire paraître un avis de décès pour un hommage public à votre défunt."],
          image:"/services/articles/prestations_34.jpg"
        },
      ]},
      {title:"Personnalisation du salon funéraire",
      tag:"perso",

      content:[
        {

          text:["N’hésitez pas à apporter ce qui vous semble nécessaire pour rendre hommage à votre défunt. Musique, photos, dessins, décoration. Nous serons là pour tout mettre en place en fonction de vos souhaits."],
          image:"/services/articles/prestations_35.jpg"
        },
      ]},
      {title:"Thanatopraxie",
      tag:"thana",

      content:[
        {

          text:["Des soins de conservation ou de toilette  peuvent être réalisés par notre prestataire professionnel."],
          image:"/services/articles/prestations_36.jpg"
        },
      ]},

    ],
  }
}
}

</script>

<template>
  <div class="mainContainer">
    <Banner textBanner="PRESTATIONS" :imageBanner="imageBanner"/>
    <div class="containerSelect">
    <select class="select" name="prestation" @change="switchSelect($event)">
      <option :value="currentArticle">Choisir une prestation</option>
      <option v-for="(elt) in content" :key="elt.tag" :value="elt.tag" >{{elt.title}}</option>
    </select>
    </div>
<!--    <div class="container">
     <div class="tags">
        <div v-for="(elt) in content" :key="elt.tag" @click="changeArticle(elt.tag)"><tagButton class="tag"  size="large" color="lightBlue" >{{elt.title}}</tagButton></div>
      </div>
    </div> -->
    <div class="container">
      <div class="articles">
        <div v-for="(elt) in filterArticle" :key="elt.title" class="article">
          <h2 > {{elt.title}} </h2>
          <p class="intro" v-for="(p, index) in elt.intro" :key="'I'+ index" v-html="p"> </p>
          <Section v-for="(section, index) in elt.content" :key="section.title" :title="section.title" :text="section.text" :image="section.image" :class="isPair(index) ? 'imageRight' : 'imageLeft'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.container{
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:3rem;
}

.containerSelect{
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:3rem;

}

.select{
  margin: 4rem 2rem;
  padding:0.5rem 1.5rem;
  background:white;
  border:1px solid var(--darkBlue);
  border-radius:10px;
}

.select select{

}

.articles{
  width:70%;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.articles .intro{
  text-align:center;
}

.article p{
  margin:1em 0 ;
}


.article h2{
  text-align:center;
}
.tags{
  width:70%;
  display:flex;
  flex-wrap:wrap;

  margin: 5em 0;
}

.tag{
  margin: 0.5rem 0.5rem;
}


@media screen and (max-width: 960px) {
  .articles{
    width:100%;
  }
  .article{
    width:100%;
  }

}

</style>

<style>
p ul{
  display:flex;
  flex-direction:column;
  padding-left:2rem;
}

p ul li {
  padding-left:0.5rem;
}

</style>
