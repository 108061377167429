<script>
export default {
  name: 'articleSectionServices',
  props:{
    title:{
      type:String,
      default:'',
    },
    text:{
      type: Array,

    },
    image:{
      type:String,
      default:'false',
    },
  },
}

</script>

<template>
<div class="articleSection">
  <h3 class="mobile">{{title}}</h3>
  <img v-if="image != 'false'" :src="`${require('@/assets/Images' + image)}` ">
  <h3 class="desk">{{title}}</h3>
  <div class="text">
    <p v-for="(p, index) in text" :key="'P'+ index" v-html="p"> </p>
  </div>
</div>
</template>

<style scoped>
.articleSection{
width:100%;
min-height:calc(500px + 3em);
margin-top:10rem;
padding-left:1rem;
padding-right:1rem;

}

.articleSection p{
  margin:1em 0 ;
  text-align: justify;
}

.text{
  margin-top:2rem;
}


.imageRight img{
width:400px;
float:right;
margin-left:4rem;
margin-bottom:2rem;
}

.imageLeft img{
width:400px;
float:left;
margin-right:4rem;
margin-bottom:2rem;
}

.mobile{
  display:none;
}

@media screen and (max-width: 1024px) {
  .imageRight img{
    width:350px;
  }
  .imageLeft img{
    width:350px;
  }

}


@media screen and (max-width: 960px) {
.articleSection{
  margin-top:4rem;
  display:flex;
  flex-direction:column;
  align-items:center;
}
.mobile{
  display:block;
  text-align:center;
}
.desk{
  display:none;
}

.imageRight img{
width:100%;
max-width:400px;
  float:none;
  margin-left:0;
  margin-bottom:0;
  margin-top:2rem;
}

.imageLeft img{
width:100%;
max-width:400px;
float:none;
margin-right:0;
margin-bottom:0;
margin-top:2rem;
}

}

</style>
