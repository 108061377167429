<script>

export default {
  name: 'cardService',
  components: {
  },
  props:{

    title: String,
    icon:String,
    link:String,
    tagName:String,
  },
  computed:{

  },
  methods:{
    cardLink:function(){
      this.$router.push({name:"Service", params:{currentArticleProps:this.tagName}});
    },
  },

}

</script>

<template>
  <div class="cardService" @click="cardLink">
    <div class="rond">
      <img :src="`${require('@/assets/Images' + icon)}` ">
    </div>
    <p v-html="title"> </p>
  </div>
</template>

<style scoped>

.cardService{
  cursor: pointer;
  position:relative;
  background: white;
  border-radius:11px;
  padding:1rem 1.5rem;
  align-items: center;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px -4px 4px  rgba(0, 0, 0, 0.20);
  transition: 0.5s;
}

.cardService:hover {
  background: var(--orange);
  transform: translateY(-20px);
}


.cardService .rond{
  width: 100px;
  height:100px;
  background:var(--paleBlue);
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items: center;
}


.cardService img{
  width:80px;
}

.cardService p{
padding-top:0.5rem;
text-align: center;
}

@media screen and (max-width: 760px) {
  .cardService{
    width:100%;
    display:flex;
    flex-direction: row;
  }
  .cardService .rond{
  }
  .cardService p{
    width:50%;
  }

}





</style>
