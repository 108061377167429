<script>
import Banner from "@/components/Banner.vue";
import Tiptap from '@/components/Tiptap.vue';

export default {
  name: 'EditContent',
  components: {
    Banner,
    Tiptap
  },
  props:{
    id:String,
    imageBanner: String,
    title:String,
    content:Object,
  },

}


</script>

<template>
  <div class="mainContainer">
    <div class="menuEditor">
      <p>BLOG</p>
    </div>
    <div class="editContent">
      <Banner  :imageBanner="imageBanner" sizeBanner="large"/>
      <div class="textWindow">
        <h2> {{title}} </h2>
        <tiptap  class="tiptapWindow" :edit="true" :contents="content"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menuEditor{
  background:white;
  height:75px;
  display:flex;
  align-items:center;
}
.menuEditor p{
 font-weight:bold;
 margin:1rem 4rem;
 cursor:pointer;
}


.editContent{
  width:100%;
  background:var(--darkBlue);

}

.textWindow{
  width:80%;
  margin:auto;
  padding:1rem;
  display:flex;
  flex-direction:column;
  align-items:center;
  background:white;
  margin-top:-100px;
  border-radius: 50px 50px 0 0;
  min-height:80vh;

}

.tiptapWindow{


}
.tiptapWindow .ProseMirror{
    border:1px solid var(--darkBlue);
}

.textWindow h2{
  margin:2rem;
}


.tiptapWindow{
  width:100%;
  padding:2rem 5rem;
}

@media screen and (max-width: 960px) {
  .textWindow{
    width:90%;
    margin-top:-80px;
  }

}

@media screen and (max-width: 960px) {
  .textWindow{
    width:96%;
    margin-top:-40px;
  }

}


</style>
