<script>

export default {
  name: 'pageNotFound',
  components: {

  },
  data(){
    return {

    }
  }


}

</script>

<template>
  <div class="mainContainer">
    <div class="pageNotFound">
      <h1>Désolé, la page que vous cherchez n'existe pas.</h1>
    </div>
  </div>
</template>

<style scoped>
.pageNotFound{
  display:flex;
  justify-content:center;
  align-items:center;
  min-height:60vh;
  background:var(--paleBlue);
}
.pageNotFound h1{
 margin : 2rem;
 text-align:center;
}

</style>
