<script>
export default {
  name: 'homeCommitments',
  props:{
    title: String,
    content: Array,
  },
  computed:{

  },
  methods:{

 }

}

</script>

<template>
<div class="container">
  <div class="homeCommitments">
    <h2>{{title}}</h2>
    <div class="commitmentsCard">
      <div v-for="card in content" :key="card.title" class="card">
        <img :src="`${require('@/assets/Images/commitments/' + card.icon)}` ">
        <h3>{{card.title}}</h3>
        <p v-html="card.text"></p>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.container{
  display:flex;
  justify-content:center;
}
.homeCommitments{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:2rem 0;

}
.commitmentsCard{
 display:flex;
 flex-wrap:wrap;
 justify-content: center;
}
.commitmentsCard .card{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:2rem;
  text-align:center;
  width:50%;
  max-width:500px;
}
.commitmentsCard h3{
  padding:1rem 0;
}

@media screen and (max-width: 760px) {
  .commitmentsCard .card{
    width:100%;
  }
}

</style>
