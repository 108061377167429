<script>


export default {
  name: 'Footer',
  components: {

  },
  methods:{
    scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<template>
  <div id="footer" >
    <div class="footerMenu">
      <router-link to="/Protection-des-donnees">Protection des données</router-link>
      <router-link to="/Mentions-legales">Mentions légales</router-link>
      <router-link to="/CGU">CGU</router-link>
      <router-link to="/Contact">Contact</router-link>
    </div>
    <div class="urgence">
      <h3>Urgence décès</h3>
      <div class="content">
        <fa class="icon " icon="phone" />
        <p>06.06.06.06.06</p>
      </div>
    </div>
    <div class="copiright">
      <p>Copyright © 2022 POMPES FUNÈBRES DE FRANCE. Tous droits réservés.</p>
      <p>Siège social : 12 rue Barthélémy Danjou, 92100 Boulogne-Billancourt</p>
    </div>

    <span @click='scrollToTop'  class="icon-container iconUp">
      <fa class="icon " icon="angle-up" />
    </span>
  </div>
</template>

<style scoped>
#footer {
position:relative;
background-image: url('@/assets/Images/fond_footer.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: top;
width:100%;
color:white;
display:flex;
flex-direction:column;
align-items:center;
padding-top:2rem;
}



#footer > div {
  margin-top:1.5rem;
  margin-bottom:1.5rem;
}

#footer p, #footer path, #footer h3 , #footer a{
  color:white;
}

#footer .urgence{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:1rem 2rem;
  border: 2px solid var(--orange);
  border-radius: 50px;
}

#footer .urgence h3{
  margin-top:0;
}
#footer .urgence .content{
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:85%;
}
#footer .footerMenu{
  display:flex;
}

#footer .footerMenu a{
  margin:0 1rem ;
}

#footer .copiright p {
  text-align:center;
  margin-top:0.3rem;
}

#footer .icon-container{
  cursor:pointer;
  position:absolute;
  background:var(--orange);
  height:60px;
  width:60px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;
  bottom:15%;
  right:5%;
  transform: translateY(50%);
  border: 2px solid var(--orange);
  transition: 0.5s ease all;
}


#footer .icon-container .icon{
  height:70%;
}
#footer .icon-container path{
  color:var(--darkBlue);
}
#footer .icon-container:hover{
  background:var(--darkBlue);
}
#footer .icon-container:hover path{
  color:var(--orange);
}

@media screen and (max-width: 760px) {
  #footer .icon-container{
    bottom:50%;
    right:5%;

  }

}

@media screen and (max-width: 560px) {
  #footer .footerMenu{
    flex-wrap:wrap;
    justify-content:center;
  }
  #footer .footerMenu a{
    width:60%;
    padding-top:1rem;
    margin:0;
  }
  #footer .copiright p {
    margin-right:3rem;
    margin-left:3rem;
  }
  #footer .icon-container{
    bottom:75%;
    right:5%;

  }

}

</style>
