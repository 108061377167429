<script>

import Slide from "@/components/Slide.vue"
export default {
  name: 'Slider',
  props:{
    title: String,
    photos: Array,
  },
  components: {
    Slide,

  },
  data(){
    return {
      currentPosition:0,
      canChangePosition:true, // disable spam click on arrow button
    }
  },
  methods:{
    addToCurrent: async function(){
        if(this.canChangePosition){
        this.canChangePosition = !this.canChangePosition;
        this.currentPosition += 1;
        setTimeout(() => { this.canChangePosition = !this.canChangePosition; }, 1000); // disable spam click on arrow button for 1000

      }
    },
    removeToCurrent: async function(){
      if(this.canChangePosition){
        this.canChangePosition = !this.canChangePosition;
        this.currentPosition -= 1; 
        setTimeout(() => { this.canChangePosition = !this.canChangePosition; }, 1000); // disable spam click on arrow button for 1000
      }
    }
  },
  computed:{

  }
}
</script>

<template>
  <div class="slider">
    <span  @click='removeToCurrent' class="icon-container iconLeft" :class="{'disableAction':!canChangePosition}">
      <fa class="icon " icon="angle-left"/>
    </span>
    <div class="slides">
      <Slide v-for="(photo, index) in photos" :key="index"  :number="index" :current="currentPosition" :photo="photo" :modulo="photos.length"/>
    </div>
    <span @click='addToCurrent'  class="icon-container iconRight" :class="{'disableAction':!canChangePosition}">
      <fa class="icon " icon="angle-right" />
    </span>
  </div>
</template>

<style scoped>


.slider{
  width:100%;
  display:flex;
  justify-content:center;
  position:relative;
  align-items:center;
  height:550px;
}

.slides{
  position:relative;
  display:flex;
  align-items:center;
  justify-content:center;
  width:70%;

}

.slider .icon{
height:70%;

}
.slider .icon-container{
  cursor:pointer;
  position:absolute;
  background:var(--lightBlue);
  border: 2px solid var(--lightBlue);
  height:50px;
  width:50px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;
  top:50%;
  transform: translateY(-50%);
  transition: 0.5s ease all;
    z-index:10;
}
.slider .icon-container path{
  color:white;
}
.slider .icon-container:hover{
  background:white;
}
.slider .icon-container:hover path{
  color:var(--lightBlue);
}
.slider .disableAction{
  cursor: auto;
  background:var(--paleBlue);
  border: 2px solid var(--paleBlue);
}

.slider .disableAction path{
  color:white;
}
.slider .disableAction:hover{
  background:var(--paleBlue);
  border: 2px solid var(--paleBlue);
}
.slider .disableAction:hover path{
  color:white;
}

.slider .iconRight{
right:8vw;
}

.slider .iconLeft{
left:8vw;
}

@media screen and (max-width: 1600px) {
  .slider{
    height:500px;
  }
}

@media screen and (max-width: 1280px) {
  .slider{
    height:400px;
  }
}

@media screen and (max-width: 1024px) {
  .slider{
    height:350px;
  }
}
@media screen and (max-width: 960px) {


}
@media screen and (max-width: 768px) {
  .slider{
    height:300px;
  }

}
@media screen and (max-width: 560px) {
  .slider{
    height:300px;
  }

}
@media screen and (max-width: 480px) {
  .slider{
    height:300px;
  }

}


</style>
