<script>
import Button from "@/components/Basics/Button.vue"

export default {
  name: 'Menu',
  components: {
    Button,
  },
  data(){
    return{
      mobile:null,
      mobileNav:null,
      windowWidth:null
    }
  },
  created(){
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  methods:{
    toggleMobileNav(){
      this.mobileNav =  !this.mobileNav;
    },

    checkScreen(){
      this.windowWidth = window.innerWidth;
      if(this.windowWidth <= 903){
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    }
  },
  watch:{

}
}
</script>

<template>
  <div id="header">
    <div id="topBar">
      <p class="desk">Contact 7/7 24/24 </p>
      <p class="mobile">Contact 7/24</p>
      <p>06.06.06.06.06</p>
      <a href="https://www.facebook.com/brieucpf" target="_blank"><fa class="icon" :icon="['fab','facebook']" /></a>
      <a href="https://www.linkedin.com/company/81545938/" target="_blank"><fa class="icon" :icon="['fab','linkedin']" /></a>
    </div>
    <div id="nav">
      <img src="@/assets/Images/logo.png" alt="Brieuc Pompe Funèbre Logo">
      <div v-show="!mobile" id="menu">
        <router-link to="/">ACCUEIL</router-link>
        <router-link to="/A-propos">A PROPOS</router-link>
        <router-link to="/Prestations">PRESTATIONS</router-link>
        <router-link to="/Blog">BLOG</router-link>
        <router-link to="/Partenaires">PARTENAIRES</router-link>
        <Button size="large" color="orange" link="/Contact">CONTACTEZ-NOUS</Button>
      </div>
      <div class="iconMenu" v-show="mobile">
        <fa @click="toggleMobileNav"  class="icon" icon="bars" :class="{'icon-active':mobileNav}"/>
      </div>
      <transition name="mobile-nav">
        <div v-show="mobileNav" id="menuDropdown">
          <router-link  to="/">ACCUEIL</router-link>
          <router-link  to="/A-propos">A PROPOS</router-link>
          <router-link  to="/Prestations">PRESTATIONS</router-link>
          <router-link  to="/Blog">BLOG</router-link>
          <router-link  to="/Partenaires">PARTENAIRES</router-link>
          <Button class="mobileButton" size="large" color="orange" link="/Contact">CONTACTEZ-NOUS</Button>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
#topBar {
  width:100%;
  background-color:var(--lightBlue);
  display:flex;
  justify-content:flex-end;
  padding-right:10vw;
  align-items:center;
}

#topBar .desk{

  display:block;
}
#topBar .mobile{
  display:none;
}

#topBar p{
  color:white;
  margin-right:2rem;
}
#topBar a{
  font-size:0;
}

#topBar .icon{
  margin:0.5rem;
  margin-right:0.5rem;
  height:1.5rem;
  cursor:pointer;
}

#nav{
  position:relative;
  display:flex;
  justify-content:space-evenly;
  align-items:center;
  background-color:white;
  height:108px;
}

#nav .iconMenu{
  display:none;
}
#nav .iconMenu .icon{
  height:2.5rem;
  cursor:pointer;
  transition: 0.8s ease all;
}

#menu a{
  font-weight:bold;
  margin-right:1rem;
  margin-left:1rem;
}
#nav img{
  height:100%;
  padding:0.2rem 0;
}

#nav .icon-active{
transform:rotate(-90deg);
}

#menuDropdown{
  display:flex;
  flex-direction:column;
  width:250px;
  background-color:white;
  position:absolute;
  top:108px;
  left:0;
  -webkit-box-shadow: 3px 4px 11px 2px rgba(32, 46, 71, 0.54);
  box-shadow: 11px 10px 9px -3px rgba(32, 46, 71, 0.54);
}

.mobile-nav-enter-active{
  transition: 1s ease all;
}

.mobile-nav-leave-active{
  transition: 1s ease all;
}


.mobile-nav-leave-to{
  transform:translateX(-250px);
}

.mobile-nav-enter{
  transform:translateX(-250px);
}

.mobile-nav-enter-to{
  transform:translateX(0);
}

#menuDropdown a{
  font-weight:bold;
  margin:1.5rem;
}
#menuDropdown .mobileButton{
  margin:1.5rem;
}

@media screen and (max-width: 1024px) {
  #topBar {
    padding-right:2vw;
    padding-left:2vw;
  }

}
@media screen and (max-width: 903px) {
  #header{

  }
  #nav{
    display:flex;
    justify-content:space-between;
    align-items:center;
    background-color:white;
    align-self:flex-start;
  }
  #nav .iconMenu{
    display:flex;
  }
  #nav img{
    height:100%;
    padding:0.2rem 0;
    margin-left:2rem;
  }
  #nav .iconMenu .icon{
    margin-right:2rem;
  }
  #nav{

  }
}

@media screen and (max-width: 760px) {

  #topBar .desk{
    display:none;
  }
  #topBar .mobile{
    display:block;

  }
}
@media screen and (max-width: 460px) {
  #topBar{
    justify-content:center;
  }

}


</style>

<style>

#topBar path{
  color:white;
  transition: 0.5s;
}

#topBar .icon:hover path{
  color:var(--orange);
}
</style>
