<script>

import Menu from "@/components/Basics/Menu.vue"
import Footer from "@/components/Basics/Footer.vue"
export default {
  name: 'App',
  components: {
    Menu,
    Footer
  }
}

</script>

<template>
  <div id="app">
    <Menu v-if="($route.name!='ListEditContent')&&($route.name!='EditContent')"/>
    <transition name="fade" mode="out-in">
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
  </transition>
    <Footer v-if="($route.name!='ListEditContent')&&($route.name!='EditContent')"/>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --lightBlue: #7FA5D6;
  --darkBlue:#354C75;
  --orange:#FFB55C;
  --beige:#FFE8D4;
  --black:#202E47;
  --paleBlue:#EDF3FB;

  font-size:16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  color:var(--black);
}

p{
  font-size:1rem;
}
a{
  font-size:1rem;
  text-decoration: none;
}
h1{
  margin:2rem 0;
  font-size: 2.5rem;
}
h2{
  margin:1.625rem 0 ;
  font-size:1.625rem;
}
h3{
  margin:1rem 0;
  font-size:1.375rem;
}
h4{
  margin:1rem 0;
  font-size:1.375rem;
}

/*.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-enter, .fade-leave-to{
  opacity:0;
} */


.mainContainer{
  width:100%;
}
.container{
  width:100%;
}
.container > div {
  width:70%;
  min-width:900px;
}

@media screen and (max-width: 1024px) {
  .container > div {
    width:100%;
    min-width:700px;
  }

}
@media screen and (max-width: 760px) {
  .container > div {
    width:100%;
    min-width:0;
  }
}

#app {
  min-height:100vh;

}
</style>
