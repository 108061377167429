<script>
import Banner from "@/components/Banner.vue";
import cardArticle from "@/components/cardArticle.vue";
import axios from 'axios';

export default {
  name: 'ListEditContent',
  components: {
    Banner,
    cardArticle,
  },
  data(){
    return {
      imageBanner:'banner_blog.jpg',
      articlesList:null,
      editable:true,
    }
  },
  mounted () {
  axios
    .get('http://api.bpf.julien-aballea.fr/articles/read.php')
    .then((response)=>{
      this.articlesList = response.data.articles
    });
}
}

</script>

<template>
  <div class="mainContainer">
    <div class="menuEditor">
      <p>BLOG</p>
    </div>
    <Banner textBanner="BLOG" :imageBanner="imageBanner"/>
    <div class="container">
      <div class="containerArticles">
                <cardArticle class="article" v-for="article in articlesList" :key="article.id" :id="article.id" :title="article.title" :thumbnail="article.thumbnail" :editable="editable"  :content="JSON.parse(article.content)"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

.mainContainer{
  background:var(--darkBlue);
}

.menuEditor{
  background:white;
  height:75px;
  display:flex;
  align-items:center;
}
.menuEditor p{
 font-weight:bold;
 margin:1rem 4rem;
 cursor:pointer;
}

.containerArticles{
  display:flex;
  justify-content:center;
  margin:4rem 0;
}

.mainContainer{
  min-height:100vh;
}

.container{
  display:flex;
  justify-content:center;
  width:100%;
}

.containerArticles{
  display:flex;
  justify-content:center;
  margin:4rem 0;
  align-self:center;
}

@media screen and (max-width: 960px) {
  .containerArticles{
    width:90%;
    flex-wrap:wrap;
    justify-content:flex-start;
  }

}
@media screen and (max-width: 830px) {
  .containerArticles{
    width:90%;
    flex-wrap:nowrap;
    align-items:center;
    flex-direction:column;
  }

  .article{
    width:100%;
  }

}

</style>
