<template>
  <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },
  props:{
    edit: {
      type: Boolean,
      default: true
    },
    contents: {
      type: Object,
      default() {
           return {
             "type": "doc",
             "content": [
               {
                 "type": "paragraph",
                 "content": [
                   {
                     "type": "text",
                     "text": "Aucun texte n'a été trouvé"
                   }
                 ]
               }
             ]
           }
       }
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  mounted() {
    this.editor = new Editor({
      content:this.contents,
      extensions: [
        StarterKit,
      ],
      editable: this.edit,
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style >

.ProseMirror p {
  margin: 1em 0;
}

.ProseMirror ul {
  padding:0 1.5em;
}

.ProseMirror li {
  padding:0 1em;
}

.ProseMirror h3{
  margin: 2em 0;
}

</style>
