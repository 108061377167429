<script>

import cardService from "@/components/cardService.vue"

export default {
  name: 'homeServices',
  components: {
    cardService,
  },
  props:{
    title: String,
    principal: Array,
    secondary: Array,
    intro: String
  },
  computed:{

  },
  methods:{

 }

}

</script>

<template>
  <div class="homeServices">
    <div class="container">
      <div class="intro">
        <h2>{{title}}</h2>
        <p>{{intro}}</p>
      </div>
      <div class="principal">
        <div class="wrapper">
          <cardService v-for="(elt, index) in principal" :key="index"  :icon="elt.icon" :title="elt.title" :tagName="elt.tag"/>
        </div>
      </div>
      <div class="secondary">
        <h3>PRESTATION COMPLÉMENTAIRES</h3>
        <div class="wrapper">
          <cardService v-for="(elt, index) in secondary" :key="index"  :icon="elt.icon" :title="elt.title" :tagName="elt.tag"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.container{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.principal{
  width: 100%;
  background:var(--darkBlue);
  display:flex;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

}

.intro{
  min-width: 500px;
  max-width:700px;
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 9rem;
  margin-top: 5rem;

}

.secondary{
  width: 100%;
  background:var(--darkBlue);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:4rem 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align:center;
}

.secondary h3{
  color:white;
  margin:8rem 0;

}

.wrapper {
  width: 80%;
  display:grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 196px);
  grid-auto-rows:196px;
  grid-row-gap:4rem;
  grid-column-gap:2rem;
  transform: translate(0, -2rem);
}

@media screen and (max-width: 760px) {
  .wrapper{
    width:60%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
  }

}
@media screen and (max-width: 660px) {
  .wrapper{
    width:70%;
  }
  .intro{
    min-width: 300px;
    max-width:500px;
  }
}
@media screen and (max-width: 560px) {
  .wrapper{
    width:80%;
  }
  .intro{
    min-width: 250px;
    max-width:350px;
  }
}
@media screen and (max-width: 460px) {
  .wrapper{
    width:90%;
  }
  .intro{
    min-width: none;
    max-width: none;
    width:95%;
  }
}


</style>
