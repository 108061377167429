<script>


export default {
  name: 'Slider',
  props:{
    number: Number,
    current:Number,
    photo: String,
    modulo:Number,
  },
  components: {

  },
  data(){
    return {
      position:this.number + this.current,
      moduloResult:0
    }
  },
  methods:{
    moduloCalc:function (x,n) {
      return ((x % n) + n) % n;
    },
  },
  watch:{
    current:function(){
      this.position = this.number + this.current;
      this.moduloResult=this.moduloCalc(this.position,this.modulo);
    }
  },
  computed:{
    styleImage:function(){

      if(this.moduloCalc(this.position,this.modulo)  == 1 ){
        return("position-center");
      }
      else if(this.moduloCalc(this.position,this.modulo)  == 2){
        return ("position-right");
      }
      else if(this.moduloCalc(this.position,this.modulo)  == 0){
        return ("position-left");
      }
      else {
        return("position-hide");
      }

    },
  }
}
</script>

<template>
  <div :class='styleImage' class="slide">
      <img :src="`${require('@/assets/Images' + photo)}` ">
  </div>
</template>

<style scoped>


.slide{
  position:absolute;
  box-shadow: 0px 0px 24px -6px #202E478A;
  border-radius: 7px;
  display:flex;

}

.position-center{
  left:50%;
  width:calc(100%/2.2);
  transform:translateX(-50%);
  transition: all 1s ease-out;
  z-index:4;
}
.position-right{
  left:calc(100%/3*2);
  width:calc(100%/3);
  transition: all 1s ease-out;
  z-index:2;
}
.position-left{
  left:0;
  width:calc(100%/3);
  transition: all 1s ease-out;
  z-index:1;
}

.position-hide{
  left:50%;
  width:calc(100%/3);
  transform:translateX(-50%);
  transition: all 1s ease-out;
  z-index:1;
  opacity:0;

}


.slide p {
  position:absolute;
  top:50%;
  left:50%;
  color: white;
  font-weight:bold;
  font-size:5rem;

}

.slide img{
  border-radius: 7px;
  width:100%;;
}

@media screen and (max-width: 1024px) {
  .position-center{
    width:calc(100%/2);
  }


}
@media screen and (max-width: 960px) {
  .position-center{
    width:calc(100%/1.7);
  }

}
@media screen and (max-width: 768px) {
  .position-center{
    width:calc(100%/1.5);
  }

}
@media screen and (max-width: 560px) {
  .position-center{
    width:calc(100%/1.3);
  }

}
@media screen and (max-width: 480px) {
  .position-center{
    width:100%;
  }

}


</style>
