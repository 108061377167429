<script>
import Banner from "@/components/Banner.vue";
import homePartners from "@/components/homePartners.vue"
import homeCommitments from "@/components/homeCommitments.vue"
import homeCardImage from "@/components/homeCardImage.vue"
import homeServices from "@/components/homeServices.vue"
import homeInfo from "@/components/homeInfo.vue"
export default {
  name: 'Home',
  components: {
    Banner,
    homePartners,
    homeCommitments,
    homeCardImage,
    homeServices,
    homeInfo
  },
  data(){
    return {
      imageBanner:'banner_home.jpg',
      partners:{
        title:"PARTENAIRE",
        logos:["partenaire_marbrerie.png","partenaire_fleuriste.png","partenaire_thanato.jpg"],
        link:"/Partenaires"
      },
      commitments:{
        title:"NOS ENGAGEMENTS",
        content:[
          {
            icon:"engagements_disponibilite.png",
            title:"Disponibilité",
            text:"Nous sommes présents à vos côtés avant, pendant et après. Notre accompagnement et nos conseils n’ont pas de limite de durée. En cas d’urgence,<strong> nous sommes joignables 7j/7 et 24h/24.</strong>"
          },
          {
            icon:"engagements_accompagnement.png",
            title:"Accompagnement",
            text:"Nous vous proposons des prestations personnalisées parce que chaque être est unique. Nous sommes là pour <strong>répondre à vos besoins et vous soulager au maximum.</strong>"
          },
          {
            icon:"engagements_proximite.png",
            title:"Proximité",
            text:"NNous sommes auprès de vous pour répondre à vos souhaits et à ceux du défunt. <strong>Nos valeurs familiales sont à votre service</strong> pour vous offrir des prestations de qualité dans la <strong>discrétion et l’empathie.</strong>"
          },
          {
            icon:"engagements_sur_mesure.png",
            title:"Sur mesure",
            text:"Nous sommes conscient qu’un décès est source de tristesse et de stress, de ce fait nous vous faisons toujours les meilleures propositions et traitons vos défunts comme les nôtres."
          },

        ]
      },
      about:{
        title:"L'ENTREPRISE",
        text:["L’entreprise Brieuc pompes funèbres, est à vos côtés pour vous accompagner dans cette épreuve. Bien plus qu’une entreprise, nous sommes une équipe soudée, des accompagnants et nous sommes vos soutiens.","Il nous tient à coeur d’être <strong>au plus près de vous et des vôtres</strong>, vous ne serez jamais seuls face aux démarches ou à l’organisation. <strong>Nous nous occuperons de tout pour vous soulager</strong> au moins de ce côté là."],
        image:"about/home_about.jpg",
        link:"/A-propos"
      },
      service:{
        title:"PRESTATIONS",
        intro:"Administrativement, nous nous occupons de toutes les déclarations et des documents obligatoires. Nous vous proposons :",
        principal:[
        {icon:"/services/icons/ceremonies.png",title:"Cérémonies laïques" ,tag:"cl"},
        {icon:"/services/icons/ceremonies.png",title:"Cérémonies religieuses" ,tag:"cr"},
        {icon:"/services/icons/cremation.png",title:"Crémation" ,tag:"crem"},
        {icon:"/services/icons/cremation.png",title:"Inhumation" ,tag:"inhu"},
        {icon:"/services/icons/transport.png",title:"Transport", tag:"trans"},
        {icon:"/services/icons/dispersions_cendres.png",title:"Dispersion des cendres", tag:"dis"},
        {icon:"/services/icons/cerceuils.png",title:"Cerceuils et enblèmes", tag:"ceremb"},
        {icon:"/services/icons/choix_urnes.png",title:"Choix des urnes", tag:"urnes"},
        {icon:"/services/icons/marbrerie.png",title:"Marbrerie", tag:"marb"},
        {icon:"/services/icons/gravures.png",title:"Gravures", tag:"grav"},
        {icon:"/services/icons/fleurs.png",title:"Fleurs" ,tag:"fleurs"},
        {icon:"/services/icons/salon_funeraire.png",title:"Salon funéraire", tag:"salon"},
      ],
        secondary:[
        {icon:"/services/icons/films.png", title:"Films hommages" , tag:"films"},
        {icon:"/services/icons/photos.png", title:"Présentations photos", tag:"pres"},
        {icon:"/services/icons/resa_hotel.png", title:"Réservation de chambres d'hôtel", tag:"reservhot"},
        {icon:"/services/icons/resa_restaurants.png", title:"Réservation de restaurants", tag:"reservres"},
        {icon:"/services/icons/avis_deces.png", title:"Avis de decès dans la presse", tag:"avis"},
        {icon:"/services/icons/salon_funeraire.png", title:"Personnalisation du salon funéraire", tag:"perso"},
        {icon:"/services/icons/thanatopraxie.png", title:"Thanatopraxie", tag:"thana"}
      ]
    },
    providence:{
      title:"PRÉVOYANCE",
      text:["Parce que prévoir ses obsèques c’est soulager ses proches, <strong>nous vous proposons des solutions qualitatives et à portée de toutes et tous.</strong> Nous trouverons les meilleures solutions pour vous et votre famille."],
      image:"home_prevoyance.jpg",
    },
    plusInfo:{
      title:"BON À SAVOIR",
      text:["Toutes nos prestations font l’objet d’un devis offert et rédigé par nos soins en fonction de vos besoins en toute transparence.",
      "L’agence funéraire Brieuc PF est composée d’un <strong> lieu d’accueil des familles, de deux bureaux privatifs et d’un salon funéraire.</strong>",
      "Nous vous accueillons à l’agence du <strong> lundi au samedi de 9h00 à 12h30 et de 14h à 19h. </strong>",
      "Nous sommes présents pour vous en cas d’urgence ou de décès <strong>7 jours sur 7 et 24h sur 24</strong>, vous pourrez joindre notre permanence funéraire au <strong>06.11.11.11.11.</strong>"]
    }
    }
  }


}

</script>

<template>
  <div class="home">
    <Banner  :imageBanner="imageBanner" sizeBanner="large"/>
    <homePartners :title="partners.title" :images="partners.logos" :link="partners.link"/>
    <homeCommitments :title="commitments.title" :content="commitments.content"/>
    <homeCardImage class="changeColorLightBlue" :title="about.title" :text="about.text" :image="about.image" :link="about.link"/>
    <homeServices :title="service.title" :intro="service.intro" :principal="service.principal" :secondary="service.secondary" />
    <homeCardImage :title="providence.title" :text="providence.text" :image="providence.image"/>
    <homeInfo :title="plusInfo.title" :text="plusInfo.text"/>
  </div>
</template>

<style>
.changeColorLightBlue{
background: var(--lightBlue);
}
.changeColorLightBlue p, .changeColorLightBlue h2, .changeColorLightBlue strong{
  color:white;
}

</style>
