<script>
export default {
  name: 'cardPartners',
  props:{
    title: String,
    content:Array,
  },
}

</script>

<template>
<div class="cardPartners">
  <h2>{{title}}</h2>
  <div v-for="card in content" :key="card.name" class="cardPartner">
    <div class="left">
      <h3>{{card.name}}</h3>
      <p>{{card.text}}</p>
    </div>
    <div class="right">
      <img :src="`${require('@/assets/Images/partners/' + card.image)}` ">
    </div>
  </div>
</div>
</template>

<style scoped>
.cardPartners h2{
  margin-bottom:2rem;
}
.cardPartner{
  display:flex;
  margin:1.5rem 0 ;
}

.cardPartner h3{
  margin-bottom:1rem;
}
.cardPartner .right{
  display:flex;
  align-items:center;
  justify-content:center;
  width:50%;
}
.cardPartner .left{
  width:50%;
  padding-right: 2rem;
}

.cardPartner img{
  max-width:100%;
}

@media screen and (max-width: 760px) {
  .cardPartner{
    flex-wrap:wrap-reverse;
  }
  .cardPartner .left{
    width:100%;
    padding-right:0;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
  .cardPartner .right{
    width:100%;
    margin-bottom:1rem;
  }
  .cardPartner h3, .cardPartners h2{
    text-align:center;
  }
}

</style>
