<script>
import Banner from "@/components/Banner.vue";
import Team from "@/components/Team.vue";
import Slider from "@/components/Slider.vue";
export default {
  name: 'About',
  components: {
    Banner,
    Team,
    Slider,
  },
  data(){
    return {
      imageBanner:'banner_about.jpg',
      intro:["L’agence funéraire Brieuc PF est composée d’un lieu d’accueil des familles, de deux bureaux privatifs et d’un salon funéraire.","Nous vous accueillons à l’agence du lundi au samedi de 9h00 à 12h30 et de 14h à 19h.","Nous sommes présents pour vous en cas d’urgence ou de décès 7 jours sur 7 et 24h sur 24, vous pourrez joindre notre permanence funérare au 06.11.11.11.11."],
      team:[{title:"Les gérants",photos:["/about/equipe_1.jpg","/about/equipe_2.jpg"]},{title:"Les porteurs",photos:["/about/equipe_3.jpg"]}],
      agency:{title:"L'agence",photos:["/about/agence_1.jpg","/about/agence_2.jpg","/about/agence_3.jpg"]},
      workplace:{title:"Le bureau",photos:["/about/bureaux_1.jpg","/about/bureaux_2.jpg","/about/bureaux_3.jpg"]},
      places:{title:"Les salons funéraires",photos:["/about/salons_1.jpg","/about/salons_2.jpg","/about/salons_3.jpg"]},
      cars:{title:"Les véhicules",photos:["/about/vehicules_1.jpg","/about/vehicules_2.jpg","/about/vehicules_3.jpg"]},
      bio:{title:"QUI SUIS-JE ?",
      text1:["Je suis un breton pures souches, je me nomme Brieuc Cazouret. Je réside à Crac’h avec ma compagne et nos deux enfants.",
"Je suis très attaché à mes origines ainsi qu’aux valeurs familiales que l’on m’a transmises. Pour moi, l’écoute, le partage, l’entraide sont indispensables pour vivre en harmonie. Ces valeurs je les partage avec les miens et nous avons à coeur, avec ma femme de faire en sorte qu’elles se perpétuent grâce à nos petits.",
"Le plus important et le maître mot c’est : FAMILLE. Et cette entreprise fait partie de notre famille. Nous menons cette aventure ensemble et on se soutient mutuellement."],
      text2:["Je suis un professionnel des pompes funèbres, je ne me suis pas lancé dans ce parcours professionnel par hasard. Ce domaine est vocationnel pour moi, j’ai toujours souhaité exercer ce métier, car il représente l’entraide, l’accompagnement, l’écoute et l’empathie, donc toutes ces valeurs qui m’ont forgé.",
"J’ai débuté ma carrière dans le domaine de la banque-assurance, mon dernier poste était gestionnaire de patrimoine. J’ai arrêté cette activité pour enfin me consacrer au métier qui me tenait à cœur depuis toujours. Il faut savoir que mon papa exerce également cette profession. La transition n’a pas été sans conséquence et ma famille a toujours été présente et m’a soutenu dans ce nouveau parcours.",
"La gestion de patrimoine est un domaine très intéressant qui me procure aujourd’hui des compétences utiles que je peux mettre au service de ma clientèle si elle le souhaite. Je peux apporter des conseils en succession, en prévoyance et en assurance."],
      text3:["Dans notre quotidien nous sommes unis comme vous l’avez compris et nous aimons partager des moments conviviaux avec nos proches. Organiser des barbecues, des fêtes, des sorties et même des vacances communes. Nous adorons nous promener en bord de mer, découvrir de jolis coins, et ici, nous sommes plutôt bien servis !",
"Nous travaillons en équipe, nous nous entraidons et ces valeurs nous les mettrons à votre service, pour que ces moments souvent compliqués et douloureux se passent le plus sereinement pour vous.",
"Nous serons à vos côtés, et nous traiterons vos défunts comme les nôtres. Avec bienveillance et respect. nous serons aussi là pour vous, pour vous soulager et vous accompagner tout au long de la prestation et même au-delà.",
"Bien à vous,",
"Brieuc Cazouret"]}
    }
  }
}

</script>

<template>
<div class="mainContainer">
  <Banner textBanner="À PROPOS" :imageBanner="imageBanner"/>
  <div class="container">
    <div class="intro">
      <p v-for="(elt, index) in intro" :key="index + elt">
        {{elt}}
      </p>
    </div>
  </div>
  <div class="container teamBackground">
      <h2>NOTRE EQUIPE</h2>
      <Team :team="team" />
  </div>
  <div class="container">
    <h3>{{agency.title}}</h3>
    <Slider :title="agency.title" :photos="agency.photos"/>
    <h3>{{workplace.title}}</h3>
    <Slider :title="workplace.title" :photos="workplace.photos"/>
    <h3>{{places.title}}</h3>
    <Slider :title="places.title" :photos="places.photos"/>
    <h3>{{cars.title}}</h3>
    <Slider :title="cars.title" :photos="cars.photos"/>
  </div>
  <div class="container">
    <div class="bio">
      <h2>{{bio.title}}</h2>
      <p v-for="(elt, index) in bio.text1" :key="'A'+ index">
        {{elt}}
      </p>
      <div class="separator">
      </div>
      <p v-for="(elt, index) in bio.text2" :key="'B'+ index">
        {{elt}}
      </p>
      <div class="separator">
      </div>
      <p v-for="(elt, index) in bio.text3" :key="'C'+ index">
        {{elt}}
      </p>
    </div>
  </div>
</div>
</template>

<style scoped>
.teamBackground{
  background-image: url('@/assets/Images/fond_notre_equipe.jpg');
  background-repeat: no-repeat;
  background-position:center;
}

.intro{
  display: flex;
  flex-direction: column;
  width:100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 0;
  width: 80%;
}
.container{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.bio{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-bottom:2rem;
}
.bio p{
  text-align:center;
  margin:1.5rem 10rem;
}

.bio .separator{
  width:100%;
  height:250px;
  background:var(--paleBlue);
}

.intro p {
margin: 1rem 0.5rem;
}

@media screen and (max-width: 1600px) {
  .bio p{
    margin:1.5rem 8rem;
  }
  .bio .separator{
    height:225px;
  }
}

@media screen and (max-width: 1280px) {
  .bio p{
    margin:1.4rem 7rem;
  }
  .bio .separator{
    height:200px;
  }
}

@media screen and (max-width: 1024px) {
  .bio p{
    margin:1.4rem 6rem;
  }
}
@media screen and (max-width: 960px) {
  .bio p{
    margin:1.3rem 4rem;
  }
  .bio .separator{
    height:175px;
  }

}
@media screen and (max-width: 768px) {
  .bio p{
    margin:1.2rem 3rem;
  }
}
@media screen and (max-width: 560px) {
  .bio p{
    margin:1.1rem 2rem;
  }
  .bio .separator{
    height:150px;
  }

}
@media screen and (max-width: 480px) {
  .bio p{
    margin:1rem 1.5rem;
  }
}
</style>
