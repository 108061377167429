<script>


export default {
  name: 'homeInfo',
  components: {
  },
  props:{
    title: String,
    text: Array,
  },
  computed:{

  },
  methods:{

 }

}

</script>

<template>
  <div class="container">
    <div class="homeInfo">
      <h2>{{title}}</h2>
      <p v-for="(val, index) in text" :key="index" v-html="val"></p>
    </div>
  </div>
</template>

<style scoped>
.container{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-bottom:4rem;
}
.homeInfo{
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
}

.homeInfo p{
margin:1rem 0;
}


</style>
