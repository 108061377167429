<script>
export default {
  name: 'Button',
  props:{
    link:{
      type:String,
    },
    paramsLink:{
      type:Object,
    },
    image:{
      type:String,
      default:'false',
    },
    size:{
      type:String,
      default:'large'
    },
    color:{
      type:String,
      default:'orange'
    }
  },

  methods:{
    buttonClick:function(){
      if (this.link){
        if(this.paramsLink){
          this.$router.push({name: this.link, params:this.paramsLink});
        }
        else{
        this.$router.push(this.link);
        }
      }
    }
  },
  computed:{
    buttonSize:function(){
      if(this.size != "large"){
        return ("button-"+this.size);
      }
      return ("button-large");
    },
    buttonColor:function(){
      if(this.color != "orange"){
        return ("button-"+this.color);
      }
      return ("button-orange");
    }
  }

}

</script>

<template>
  <button @click="buttonClick" class="button" :class="[buttonSize,buttonColor]" >
    <slot></slot>
  </button>
</template>

<style scoped>
.button{
  border:none;
  color:white;
  border-radius:25px;
  font-weight:bold;
  transition: 0.5s ease all;
  cursor:pointer;
}

.button-large{
  padding:0.4rem 0.6rem;
}

.button-small{
padding:0.3rem 0.5rem;
}

.button-xsmall{
  padding:0.2rem 0.4rem;
}

.button-orange{
  background-color:var(--orange);
  border: 2px solid var(--orange);
}
.button-orange:hover{
  background-color:white;
  color:var(--orange);
}

.button-blue{
  background-color:var(--blue);
  border: 2px solid var(--blue);
}

.button-blue:hover{
  background-color:white;
  color:var(--blue);
}

.button-lightBlue{
  background-color:var(--lightBlue);
  border: 2px solid var(--lightBlue);
}

.button-lightBlue:hover{
  background-color:white;
  color:var(--lightBlue);
}

</style>
