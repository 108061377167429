<script>
export default {
  name: 'Team',
  props:{
    team: Array,
  },
  computed:{

  },
  methods:{

 }

}

</script>

<template>
  <div class="team">
    <div v-for="(elt, index) in team" :key="index" class="section">
      <h3> {{elt.title}}</h3>
      <div class="imageTeam">
        <img v-for="(image,index) in elt.photos" :key="index" :src="`${require('@/assets/Images' + image)}` ">
      </div>
    </div>
  </div>

</template>

<style scoped>
.team{
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top:1rem;
  padding-bottom:5rem;
}
.team .section{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.team img{
  max-height:300px;
  margin:1rem;
  max-width:100%;

}

.team .imageTeam{
  display: flex;
  width:100%;
  max-width:100%;
  justify-content: center
}

@media screen and (max-width: 480px) {
  .team img{
    height:200px;

  }
}
</style>
