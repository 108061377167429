<script>
export default {
  name: 'homePartners',
  props:{
    title: String,
    images: Array,
    link: String
  },
  computed:{

  },
  methods:{

 }

}

</script>

<template>
<div class="homePartner">
  <h2>{{title}}</h2>
  <div  class="images">
    <img v-for="imageHomePartners in images" :key="imageHomePartners" :src="`${require('@/assets/Images/partners/' + imageHomePartners)}` ">
  </div>
  <router-link to="/Partenaires">Découvrez  tous nos partenaires</router-link>
</div>
</template>

<style scoped>
.homePartner{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:2rem 0;
  background:var(--paleBlue);
}

.homePartner a {
  text-decoration: underline;
}

.homePartner .images{
  display:flex;
  justify-content: center;
}

.homePartner img{
  height:70px;
  margin:1rem;
}

@media screen and (max-width: 760px) {

  .homePartner img{
    height:50px;
    margin:0.75rem;
  }
}
@media screen and (max-width: 460px) {

  .homePartner img{
    height:35px;
    margin:0.5rem;
  }
}


</style>
